import React from "react";
import "./assets/css/app.css";
import "semantic-ui-css/semantic.min.css";
import Home from "./components/home";
import AuthorizedRoute from "./components/routes/AuthorizedRoute";
import Login from "./components/auth/Login";
import GuestRoute from "./components/routes/GuestRoute";
import Esqueci from "./components/auth/Esqueci";

const App = ({ location }) => {
  return (
    <div className="container">
      <AuthorizedRoute location={location} path="/" exact component={Home} />
      <GuestRoute location={location} path="/login" exact component={Login} />
      <GuestRoute
        location={location}
        path="/esqueci"
        exact
        component={Esqueci}
      />
    </div>
  );
};

export default App;
