import React, { useState, useEffect } from "react";
import { Dimmer, Form, Grid, Header, Icon, Loader } from "semantic-ui-react";
import api from "../../services/api";
import MainContainer from "../main-container";
import CardPedido from "./CardPedido";

const Home = () => {
  const [pedidos, setPedidos] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [timer, setTimer] = useState(null);
  const [filter, setFilter] = useState({
    data_inicial: "",
    data_final: "",
    search: "",
    status: "",
    take: 10,
  });

  const getData = (page, params = {}) => {
    setLoading(true);
    api
      .get("/api/totvs/funcionario/folha")
      .then((res) => {
        setPedidos(res.data);
        setLoading(false);
      })
      .catch((err) => setLoading(false));
  };

  const handleStatusFilterChanged = (e, { value }) => {
    setFilter({ ...filter, status: value });
    getData(currentPage, {
      ...filter,
      status: value,
    });
  };

  const handleDataFilterChanged = (e, { name, value }) => {
    setFilter({ ...filter, [name]: value });

    getData(currentPage, { ...filter, [name]: value });
  };

  const handleSearch = (e, { value }) => {
    setTimer(clearTimeout(timer));
    setFilter({ ...filter, search: value });
    setTimer(
      setTimeout(() => {
        getData(null, { ...filter, search: value });
      }, 1000)
    );
  };

  const handleRecordsPerPageChange = (e, { value }) => {
    setFilter({
      ...filter,
      take: value,
    });
    getData(null, {
      ...filter,
      take: value,
    });
  };

  useEffect(() => {
    getData(currentPage);
  }, []);

  return (
    <MainContainer>
      <Header as="h3">Seus Contracheques disponíveis</Header>
      <Header>
        <Form></Form>
      </Header>
      {loading && (
        <Dimmer active>
          <Loader size="small">Carregando Dados</Loader>
        </Dimmer>
      )}
      <Grid>
        {pedidos.map((folha) => {
          return (
            <Grid.Column
              width={8}
              key={`${folha.MESCOMP}-${folha.ANOCOMP}-${folha.PERIODO}`}
            >
              <CardPedido
                titulo={`${
                  folha.PERIODO == 1
                    ? "Adiantamento de Salário"
                    : folha.PERIODO == 2
                    ? "Contracheque"
                    : folha.PERIODO == 3
                    ? "13º Salário"
                    : "Competência"
                } ${folha.MESCOMP}/${folha.ANOCOMP} - ${folha.PERIODO}`}
                folha={folha}
              ></CardPedido>
            </Grid.Column>
          );
        })}
      </Grid>
    </MainContainer>
  );
};

export default Home;
