import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./style.css";
import logo from "../../assets/images/logo.png";
import { Icon } from "semantic-ui-react";
import MenuIcon from "@material-ui/icons/Menu";
import { grey } from "@material-ui/core/colors";

const NavBar = () => {
  const [activeMenu, setActiveMenu] = useState(false);
  const [colaborador, setColaborador] = useState({});
  const [menus, setMenu] = useState([]);

  const toggleActiveMenu = () => {
    setActiveMenu(!activeMenu);
  };

  const createMenu = (menus, mobile = false) => {
    return menus.map((menu) => {
      return menu.subMenus.length ? (
        <li key={menu.rota}>
          <Link to={`/${menu.rota}`}>{menu.nome}</Link>
          <ul className={mobile ? "sub-menu-mobile" : "sub-menu"}>
            {createMenu(menu.subMenus)}
          </ul>
        </li>
      ) : (
        <li key={menu.rota}>
          <Link to={`/${menu.rota}`}>{menu.nome}</Link>
        </li>
      );
    });
  };

  useEffect(() => {
    const colaborador = JSON.parse(localStorage.getItem("colaborador"));
    const data = []; //JSON.parse(localStorage.getItem("pelicano-menu"));

    const menus = data ? data.map((menu) => menu) : [];

    setMenu(menus);
    setColaborador(colaborador);
  }, []);

  return (
    <nav className="navbar navbar-expand-lg navbar-light bg-dark nav">
      <div className="logo">
        <Link to="/">
          <img src={logo} alt="Welton Jóias"></img>
        </Link>
      </div>
      <div className="mobile-menu" onClick={toggleActiveMenu}>
        <div className="employee-name">
          {colaborador.NOME ? colaborador.NOME : ""}
        </div>
        <Link
          to="/login"
          onClick={() => {
            localStorage.removeItem("pelicano-token");
            localStorage.removeItem("colaborador");
          }}
        >
          <Icon name="log out" style={{ color: "#fff" }}></Icon>
        </Link>
      </div>
      <div
        className={`menu-mobile-items ${
          activeMenu ? "slide-mobile-menu-items" : ""
        }`}
      >
        <ul className="menu clearfix">{createMenu(menus, true)}</ul>
      </div>
      <div className="items">
        <div className="employee-name">
          {colaborador.NOME ? colaborador.NOME : ""}
        </div>
        <ul className="menu clearfix">{createMenu(menus)}</ul>
        <div className="profile">
          <Link
            to="/login"
            onClick={() => {
              localStorage.removeItem("pelicano-token");
              localStorage.removeItem("colaborador");
            }}
          >
            <Icon name="log out" style={{ color: "#fff" }}></Icon>
          </Link>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
