import React from "react";

const ProgressBar = ({ progresso }) => {
    return (
        <div className="progress">
            <div
                className={`progress-bar progress-bar-striped progress-bar-animated ${
                    progresso >= 100 ? "bg-success" : null
                }`}
                role="progressbar"
                style={{ width: `${progresso}%` }}
                aria-valuenow={progresso}
                aria-valuemin="0"
                aria-valuemax="100"
            ></div>
        </div>
    );
};

export default ProgressBar;
