import axios from "axios";

const api = axios.create({
  baseURL: `https://api.pelicano.eng.br`,
});

api.interceptors.request.use((request) => {
  request.headers.Authorization = `Bearer ${localStorage.getItem(
    "pelicano-token"
  )}`;

  request.headers.ContentType = "application/json";
  request.headers.Accept = "application/json";

  return request;
});

export default api;
