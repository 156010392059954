import axios from "../../services/api";
import React, { useState } from "react";
import { Button, Form, Message } from "semantic-ui-react";
import Error from "../messages/error";
import logo from "../../assets/images/logo-pelicano.png";
import "./style.css";

const Esqueci = ({ history }) => {
  const [cpf, setCpf] = useState("");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(null);

  const submit = async () => {
    setLoading(true);
    setError(null);

    await axios
      .post(`/api/totvs/user/forget`, {
        cpf,
      })
      .then(async (res) => {
        setSuccess(
          "Se o seu email existir em nossa base, enviaremos um email com a nova senha"
        );

        setLoading(false);
      })
      .catch((err) => {
        setError(
          "Erro ao solicitar a senha. Verifique se você informou um cpf válido"
        );
        setLoading(false);
      });
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {success && <Message success>{success}</Message>}
      <Form className="form-login">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={logo}
            alt="Pelicano Engenharia"
            style={{
              width: 200,
              margin: "40px 0px",
            }}
          ></img>
        </div>
        <Form.Field>
          <label htmlFor="cpf" style={{ color: "rgb(78 83 102)" }}>
            Cpf
          </label>
          <input
            type="text"
            name="cpf"
            placeholder="Apenas números Ex: 12345678910"
            value={cpf}
            onChange={(e) => {
              setCpf(e.target.value);
            }}
          />
          {error && <Error text={error}></Error>}
        </Form.Field>

        <Form.Field>
          <a href="/login">
            <span>Lembrei</span>
          </a>
        </Form.Field>

        <Button onClick={submit} disabled={loading} loading={loading} primary>
          Recuperar Senha
        </Button>
      </Form>
    </div>
  );
};

export default Esqueci;
