/* eslint-disable react/forbid-prop-types */
import React from "react";
import { Route, Redirect } from "react-router-dom";

const AuthorizedRoute = ({ component: Component, ...rest }) => {
  const token = localStorage.getItem("pelicano-token");

  const menus = [];

  const getMenus = (data) => {
    for (var menu of data) {
      if (menu.subMenus.length) {
        menus.push("/" + menu.rota);
        getMenus(menu.subMenus);
      } else {
        menus.push("/" + menu.rota);
      }
    }
  };

  const canAccessRoute = (props) => {
    const path = props.match.path;

    const editingPage = path.split(":").length > 1;

    if (editingPage) return true;

    return path === "/" ? true : menus.includes(props.match.path);
  };

  const data = []; //JSON.parse(localStorage.getItem("pelicano-menu")) || [];

  getMenus(data);

  return (
    <Route
      {...rest}
      render={(props) => {
        return token ? (
          canAccessRoute(props) ? (
            <Component {...props} />
          ) : (
            <Redirect to="/" />
          )
        ) : (
          <Redirect to="/login" />
        );
      }}
    />
  );
};

export default AuthorizedRoute;
