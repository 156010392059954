import React, { useState } from "react";
import { Card, Icon, Popup } from "semantic-ui-react";
import ProgressBar from "../elementos/ProgressBar";
import api from "../../services/api";

const CardPedido = ({ titulo, folha, history }) => {
  const [downloading, setDownloading] = useState({});

  const getContracheque = () => {
    setDownloading({
      id: `${folha.ANOCOMP}-${folha.MESCOMP}-${folha.PERIODO}`,
    });
    api
      .get(
        `/api/totvs/funcionario/folha/${folha.ANOCOMP}/${folha.MESCOMP}/${folha.PERIODO}`
      )
      .then((res) => {
        let a = document.createElement("a");
        a.href = "data:application/octet-stream;base64," + res.data;
        a.download = `contracheque-${folha.MESCOMP}-${folha.ANOCOMP}-${folha.PERIODO}.pdf`;
        a.click();
        setDownloading({});
      })
      .catch((err) => setDownloading({}));
  };

  return (
    <Card fluid>
      <Card.Content>
        <Card.Header>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <div
              onClick={getContracheque}
              style={{ cursor: "pointer", color: "#3664b9" }}
            >
              {titulo}
            </div>

            <div style={{ float: "right" }}>
              <Popup
                content="Download em PDF"
                trigger={
                  <Icon
                    disabled={
                      downloading.id ===
                      `${folha.ANOCOMP}-${folha.MESCOMP}-${folha.PERIODO}`
                    }
                    name="file pdf"
                    onClick={getContracheque}
                    style={{
                      cursor:
                        downloading.id !==
                        `${folha.ANOCOMP}-${folha.MESCOMP}-${folha.PERIODO}`
                          ? "pointer"
                          : "",
                    }}
                  ></Icon>
                }
              />
            </div>
          </div>
        </Card.Header>
        <Card.Meta>{folha.cliente}</Card.Meta>
        <Card.Description>{folha.descricao}</Card.Description>
      </Card.Content>
      <Card.Content extra>
        <ProgressBar progresso={folha.progresso}></ProgressBar>

        <label>
          <strong>{folha.status}</strong>
        </label>
      </Card.Content>
    </Card>
  );
};

export default CardPedido;
