import axios from "../../services/api";
import React, { useState } from "react";
import { Button, Form } from "semantic-ui-react";
import Error from "../messages/error";
import logo from "../../assets/images/logo-pelicano.png";
import "./style.css";
import Warn from "../messages/warn";

const Login = ({ history }) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPasswordConfirmation, setNewPasswordConfirmation] = useState("");
  const [funcionarioId, setFuncionarioId] = useState(null);
  const [error, setError] = useState(null);
  const [noEmailError, setNoEmailError] = useState(null);
  const [firstAccess, setFirstAccess] = useState(false);
  const [loading, setLoading] = useState(false);
  const submit = async () => {
    setLoading(true);
    setError(null);
    try {
      await axios
        .post(`/api/totvs/user`, {
          username: userName,
        })
        .then(async (res) => {
          console.log(res.data);

          if (!res.data.ATIVO) {
            setError("Usuário não encontrado");
            setLoading(false);
            return;
          }

          if (res.data.first_access && !firstAccess) {
            setFirstAccess(true);
            setFuncionarioId(res.data.id);
            setLoading(false);
            return;
          }

          if (!res.data.EMAIL && !noEmailError && !firstAccess) {
            setNoEmailError(
              "Você ainda não possui um email cadastrado. Para recuperar sua senha, será necessário informar um email válido. Você pode fazer isso agora se desejar."
            );
            setFuncionarioId(res.data.id);
            setLoading(false);
            return;
          }

          const auth = await axios
            .post(`/oauth/token`, {
              username: userName,
              password,
              grant_type: "password",
              client_id: "93c83fad-67c5-4a18-a68d-8b6a85e31e5f",
              client_secret: "b7sFIhb36aAqF3OiaGSLysKZHFcPb7znb8k2LNFw",
            })
            .catch((err) => setLoading(false));

          localStorage.setItem("pelicano-token", auth.data.access_token);
          localStorage.setItem("colaborador", JSON.stringify(res.data));

          if (firstAccess) {
            await axios
              .post(`/api/totvs/funcionario/${funcionarioId}/password`, {
                password: newPassword,
                password_confirmation: newPasswordConfirmation,
              })
              .then((res) => {
                setFirstAccess(false);
                if (!res.data.EMAIL && !noEmailError) {
                  setNoEmailError(
                    "Você ainda não possui um email cadastrado. Para recuperar sua senha, será necessário informar um email válido. Você pode fazer isso agora se desejar, ou clique em Continuar"
                  );
                  setFuncionarioId(res.data.id);
                  setLoading(false);
                  return;
                }

                setLoading(false);
                history.push("/");
              })
              .catch((err) => {
                setError("Erro ao alterar sua senha");
                setFirstAccess(true);
                setLoading(false);
              });

            setFirstAccess(false);
            return;
          }

          if (noEmailError && email) {
            await axios.put(`/api/totvs/funcionario/${funcionarioId}`, {
              email: email,
            });
          }

          setLoading(false);
          history.push("/");
        })
        .catch((err) => setLoading(false));
    } catch (err) {
      if (err.response) {
        setLoading(false);
        setError(err.response.data.error.error);
      } else {
        setLoading(false);
        setError("Erro ao realizar o Login");
      }
    }
  };

  return (
    <div
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Form className="form-login">
        <div style={{ display: "flex", justifyContent: "center" }}>
          <img
            src={logo}
            alt="Pelicano Engenharia"
            style={{
              width: 200,
              margin: "40px 0px",
            }}
          ></img>
        </div>
        <Form.Field>
          <label htmlFor="userName" style={{ color: "rgb(78 83 102)" }}>
            Cpf
          </label>
          <input
            type="tel"
            name="userName"
            placeholder="Somente os números do seu cpf Ex: 12345678901"
            value={userName}
            onChange={(e) => {
              setUserName(e.target.value);
            }}
          />
          {error && <Error text={error}></Error>}
        </Form.Field>
        {noEmailError && (
          <Form.Field>
            {noEmailError && <Warn text={noEmailError}></Warn>}
            <label htmlFor="userName" style={{ color: "rgb(78 83 102)" }}>
              Email
            </label>
            <input
              type="email"
              name="email"
              placeholder="exemplo: maria@hotmail.com ou joão@gmail.com"
              value={email}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
          </Form.Field>
        )}

        <Form.Field>
          <label htmlFor="password" style={{ color: "rgb(78 83 102)" }}>
            {firstAccess && "Senha Atual"}
            {!firstAccess && "Senha"}
          </label>
          <input
            type="password"
            name="password"
            placeholder="Digite sua senha"
            value={password}
            onChange={(e) => {
              setPassword(e.target.value);
            }}
          />
        </Form.Field>

        <Form.Field>
          <a href="/esqueci">
            <span>Esqueci a Senha</span>
          </a>
        </Form.Field>

        {firstAccess && (
          <Form.Field>
            <Warn text="Para continuar, você precisa escolher uma nova senha:"></Warn>

            <label htmlFor="userName" style={{ color: "rgb(78 83 102)" }}>
              Nova Senha
            </label>
            <input
              type="password"
              name="new_password"
              value={newPassword}
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
            />

            <label htmlFor="userName" style={{ color: "rgb(78 83 102)" }}>
              Confirme a Nova Senha
            </label>
            <input
              type="password"
              name="password_confirmation"
              value={newPasswordConfirmation}
              onChange={(e) => {
                setNewPasswordConfirmation(e.target.value);
              }}
            />
          </Form.Field>
        )}

        <Button
          positive={noEmailError || firstAccess}
          onClick={submit}
          disabled={loading}
          loading={loading}
          primary={!noEmailError}
        >
          {noEmailError && "Continuar"}
          {firstAccess && "Alterar Senha"}
          {!noEmailError && !firstAccess && "Login"}
        </Button>
      </Form>
    </div>
  );
};

export default Login;
